<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="80%"
      origin="top center"
      class="dialog pa-0"
	  persistent
    >
      <v-card>
        <v-card-title class="pa-0 z-index-front">
          <v-toolbar light elevation="1" class="">
            <v-toolbar-title>{{ isEditMode ? $t('edit_expense') : $t('add_expense') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="px-0 pb-0">
					<v-container class="grey lighten-4 pt-1">
						<v-form>
							<v-row
								no-gutters
							>
								<v-col
									cols="4"
									class="pl-4"
								>
									<v-card
										class="pa-2 grey lighten-4"
										outlined
										tile
									>
									<label class="form-label text-uppercase" for="formdata-ref-number">{{ $t('ref_no') }}</label>
									<v-text-field
										v-model="formData.refNumber"
										:label="$t('enter_ref_no')"
										solo
										class="app-theme-input-border"
										flat
										required
										dense
										id="formdata-ref-number"
									></v-text-field>

									<label class="form-label text-uppercase" for="formdata-payee">{{ $t('payee') }}</label>
									<v-select
										v-model="formData.payee"
										:items="payeeData"
										:rules="[v => !!v || $t('field_required')]"
										id="formdata-payee"
										:label="$t('who_did_you_pay')"
										solo
										flat
										class="app-theme-input-border"
										dense
									>
									</v-select>

									<label class="form-label text-uppercase" for="formdata-terms">{{ $t('payment_account') }}</label>
									<v-select
										v-model="formData.terms"
										:items="paymentAccountData"
										:rules="[v => !!v || $t('field_required')]"
										id="formdata-terms"
										:label="$t('select_terms')"
										solo
										flat
										class="app-theme-input-border"
										dense
									>
									</v-select>

									<label class="form-label text-uppercase" for="formdata-payment-method">{{ $t('payment_method') }}</label>
									<v-select
										v-model="formData.paymentMethod"
										:items="paymentMethodData"
										:rules="[v => !!v || $t('field_required')]"
										id="formdata-payment-method"
										label=""
										solo
										flat
										class="app-theme-input-border"
										dense
									>
									</v-select>

									<label class="form-label text-uppercase" for="formdata-notes">{{ $t('notes') }}</label>
									<v-textarea
										solo
										:label="$t('type_notes_here')"
										class="app-theme-input-border"
										flat
										id="formdata-notes"
										rows="3"
									></v-textarea>

									</v-card>
								</v-col>
								<v-col
									class="white pr-4 pl-4"
								>
									<v-card
										class="pa-2"
										flat
									>
										<table class="mb-0 w-100" dense>
												<thead class="grey lighten-3">
													<tr>
														<th class="pl-3 text-left text-uppercase text-subtitle-2 font-weight-black py-2">
															{{ $t('category') }}
														</th>
														<th class="pl-3 text-left text-uppercase text-subtitle-2 font-weight-black">
															{{ $t('description') }}
														</th>
														<th class="text-right text-uppercase text-subtitle-2 font-weight-black" width="15%">
															{{ $t('amount') }}
														</th>
														<th class="text-right text-uppercase text-subtitle-2 font-weight-black" width="15%">
															{{ $t('tax') }}
														</th>
														<th width="5%">&nbsp;</th>
													</tr>
												</thead>
												<tbody>
													<tr
														v-for="item in formData.billCategories"
														:key="item.id"
													>
														<td class="pl-0 pr-3 pt-2">
															<v-select
																v-model="item.category"
																:items="categoryData"
																:rules="[v => !!v || $t('field_required')]"
																:label="$t('select_category')"
																solo
																flat
																class="app-theme-input-border"
																dense
															>
															</v-select>
														</td>
														<td class="px-0 pr-3">
															<v-text-field
																v-model="item.description"
																solo
																class="app-theme-input-border"
																flat
																required
																dense
															></v-text-field>
														</td>
														<td class="px-0 pr-3">
															<v-text-field
																v-model="item.amount"
																solo
																flat
																required
																dense
																class="text-right app-theme-input-border"
															></v-text-field>
														</td>
														<td>
															<v-select
																v-model="item.tax"
																:items="taxData"
																:rules="[v => !!v || $t('field_required')]"
																solo
																flat
																class="app-theme-input-border"
																dense
															>
															</v-select>
														</td>
														<td>
															<v-btn
																icon
																color="red"
																small
																@click="onRemoveCategory(item.id)"
															>
																<v-icon>mdi-close</v-icon>
															</v-btn>
														</td>
													</tr>
												</tbody>
										</table>
										<hr class="mb-2">
										<v-btn outlined text small @click="onAddCategory" color="#0889a0" class="mb-2 font-weight-bold py-4 text-capitalize">
											<v-icon>mdi-plus</v-icon>
											{{ $t('add_category') }}
										</v-btn>
										<hr />

										<v-row class="mt-4">
											<v-col cols="8">
												<label class="text-uppercase form-label">{{ $t('attachments') }}</label>
												<v-card
													class="text-center pa-5 border-dashed"
													width="400"
													flat
													@dragover="dragover"
													@dragleave="dragleave"
													@drop="drop"
													:class="[isBrowseFileHovered ? 'grey lighten-2' : '']"
												>
													<v-list
														subheader
														two-line
														v-if="fileList.length"
													>
														<v-subheader class="text-subtitle-1">{{ $t('files') }}</v-subheader>

														<v-list-item
															v-for="(file, index) in fileList"
															:key="file.name"
															dense
														>
															<v-list-item-avatar height="20">
																<v-icon
																	color="#0889a0"
																	v-text="'mdi-image'"
																></v-icon>
															</v-list-item-avatar>

															<v-list-item-content>
																<v-list-item-title v-text="file.name"></v-list-item-title>
															</v-list-item-content>

															<v-list-item-action>
																<v-btn icon @click="removeFile(index)">
																	<v-icon color="red">mdi-close</v-icon>
																</v-btn>
															</v-list-item-action>
														</v-list-item>
													</v-list>
													<input
														type="file"
														multiple
														name="filelist[images][]"
														id="file-input-expense" 
														class="display-none"
														@change="onFileBrowseChange"
														ref="fileExpenseAttachment"
														accept=".jpg,.jpeg,.png"
													/>
													<label for="file-input-expense" class="form-label pa-5" title="Click to browse file">
														<h6 class="pa-5">{{ $t('browse_drop_image') }}</h6>
													</label>
													<v-btn small outlined text class="text-capitalize pa-3" color="#0889a0" @click="onUploadFile">
														<v-icon>mdi-upload</v-icon>
														{{ $t('upload') }}
													</v-btn>
												</v-card>
											</v-col>
											<v-col>
												<v-row>
													<v-col class="text-right">{{ $t('subtotal') }}</v-col>
													<v-col class="text-right">${{ subTotal }}</v-col>
												</v-row>
												<hr>
												<v-row>
													<v-col class="text-right">{{ $t('tax') }}</v-col>
													<v-col class="text-right">${{ taxTotal }}</v-col>
												</v-row>
												<hr>
												<v-row>
													<v-col class="text-right font-weight-bold">{{ $t('total') }}</v-col>
													<v-col class="text-right font-weight-bold">${{ subTotal + taxTotal }}</v-col>
												</v-row>
											</v-col>
										</v-row>
																				
									</v-card>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-start">
          <v-btn @click="onSaveForm" class="text-capitalize btn-primary">{{ isEditMode ? $t("update_send") :  $t("save_send") }}</v-btn>
					<v-btn outlined text class="text-capitalize" @click="onClose">{{ $t('cancel') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["open", "isEditMode", "formValues"],
	data() {
		return {
			formData: {
				billCategories: [{
					id: 1,
					category: '',
					description: '',
					amount: 0,
					tax: 0,
				}],
			},
			payeeData: [{
				value: 1,
				text: 'Payee Name',
			}, {
				value: 2,
				text: 'Sample Payee'
			}],
			termsData: [{
				value: 1,
				text: '1 Year'
			}],
			paymentAccountData: [{
				value: 1,
				text: 'Account One'
			}],
			paymentMethodData: [{
				value: 1,
				text: 'Paypal'
			}],
			categoryData: [],
			taxData: [],
			fileList: [],
			isBrowseFileHovered: false,
		}
	},

	created() {
		if(this.formValues) {
			this.formData = {...this.formValues};
		}
	},

	watch: {
		formValues(values) {
			if(values) {
				this.formData = {
					...values, 
					// payee: values.payee.value,
					// refNumber: values.billNumber,
				// Remove billCategories for actual data
					billCategories: [],
				};
			} else {
				this.formData = {
					billCategories: []
				};
			}
		}
	},

  computed: {
    showDialog: {
      get() {
				this.formData = {
					billCategories: [],
				};
        return this.open;
      },
      set(value) {
        this.$emit("toggle", value);
      },
    },
		subTotal(){
			if(this.formData.billCategories && this.formData.billCategories.length) {
				return this.formData.billCategories.reduce((c, n) => c + Number(n.amount) ,0);
			}
			return 0;
		},

		taxTotal() {
			if(this.formData.billCategories && this.formData.billCategories.length) {
				return this.formData.billCategories.reduce((c, n) => c + Number(n.tax) ,0);
			}
			return 0;
		}
  },

  methods: {
    onClose() {
			this.formData = {
				billCategories: [],
			};
			this.fileList = [];
      this.$emit("toggle");
    },

    onSaveForm() {
      this.$emit("toggle");
    },

		onAddCategory() {
			this.formData.billCategories.push({
				id: Date.now(),
				category: '',
				description: '',
				amount: 0,
				tax: 0,
			});
		},

		onRemoveCategory(id) {
			this.formData.billCategories = this.formData.billCategories.filter( record =>  record.id !== id);
		},

		onFileBrowseChange() {
      this.fileList = [...this.$refs.fileExpenseAttachment.files];
    },

    removeFile(i) {
      this.fileList.splice(i, 1);
    },

    dragover(event) {
      event.preventDefault();
      this.isBrowseFileHovered = true;
    },

    dragleave(event) {
      this.isBrowseFileHovered = false;
    },

    drop(event) {
      event.preventDefault();
      this.$refs.fileExpenseAttachment.files = event.dataTransfer.files;
      this.onFileBrowseChange();
      this.isBrowseFileHovered = false;
    },

		onUploadFile() {
			// Upload to server here
			console.log('Uploading...');
		}
  },
};
</script>

<style lang="scss" scoped>
	$button-bg-color: #0171a1;
	$form-label: #819FB2;
	.dialog {
		z-index: 9999;
	}
	.form-label{ 
		color: $form-label;
	}
	.w-100 {
		width: 100%;
	}
	.display-none {
		display: none;
	}

	hr {
		border-color: #ebf1f5;
	}

	th {
		color: $form-label;
		font-weight: bold;
	}
	.btn-primary {
		background-color: $button-bg-color !important;
		color: #fff !important;
	}
	.border-dashed {
		border: 1px dashed $form-label !important;
	}
	.z-index-front {
    z-index: 1;
  }
</style>
<style lang="scss">
	.app-theme-input-border{
		.v-input__slot {
			border: 1px solid #b5d0e1 !important;
		}
		label {
			color: #a3c6dd !important;
		}
	}
</style>