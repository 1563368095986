<template>
  <div class="widgetContainer full-width">
		<v-breadcrumbs :items="breadCrumb" class="pl-0">
			<template v-slot:item="{ item }">
				<v-breadcrumbs-item
					:href="item.href"
					@click.prevent="$router.push(item.href)"
					:disabled="item.disabled"
					class="font-weight-black"
				>
					{{ item.text }}
				</v-breadcrumbs-item>
			</template>
			<template v-slot:divider>
				<v-icon>mdi-chevron-right</v-icon>
			</template>
		</v-breadcrumbs>
    <div class="widgetContainer__header-fixed pl-0">
      <div class="headerExport header-1">
				{{ $t('ref_number') }} {{ refNumber }}
      </div>
    </div>
    <v-app class="vue-app">
      <v-container>
				<div class="d-flex justify-end mb-4">
					<v-btn
						class="text-capitalize blue--text mr-2"
						large
						outlined
						@click="onToggleEditModal"
					>
						{{ $t('edit_invoice') }}
					</v-btn>
					<v-menu offset-y left min-width="100">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" primary v-on="on" color="primary" text outlined large>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
							<v-list-item link>
								<v-list-item-title>{{ $t('print') }}</v-list-item-title>
							</v-list-item>
							<v-list-item light>
								<v-list-item-title>{{ $t('void') }}</v-list-item-title>
							</v-list-item>
							<v-list-item light @click="showDeleteModal = true">
								<v-list-item-title class="red--text">{{ $t('delete') }}</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
				<v-card outlined>
					<v-card-text>
						<v-row class="jus justify-space-between">
							<v-col>
								<div>
									<span class="form-label text-uppercase">{{ $t('payee') }}</span>
									<p class="subtitle-1 font-weight-medium">{{ data.payee }}</p>
								</div>
							</v-col>
							<v-col>
								<span class="form-label text-uppercase">{{ $t('payment_method') }}</span>
								<p class="subtitle-1 text-uppercase font-weight-medium">{{ data.paymentMethod }}</p>
							</v-col>
							<v-col>
								<span class="form-label text-uppercase">{{ $t('payment_account') }}</span>
								<p class="subtitle-1 text-uppercase font-weight-medium">{{ data.paymentAccount }}</p>
							</v-col>
							<v-col>
								<span class="form-label text-uppercase">{{ $t('payment_date') }}</span>
								<p class="subtitle-1 text-uppercase font-weight-medium">{{ data.paymentDate }}</p>
							</v-col>
						</v-row>
					</v-card-text>
					<hr>
					<v-card-text>
						<h5 class="subtitle-1 font-weight-bold text-dark">{{ $t('expense_outstanding_transactions') }}</h5>
					</v-card-text>
					<hr>
					<v-simple-table dense class="v-table-middle-align">
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-uppercase form-label">{{ $t('category') }}</th>
									<th class="text-uppercase form-label">{{ $t('description') }}</th>
									<th class="text-uppercase form-label">{{ $t('amount') }}</th>
									<th class="text-uppercase form-label">{{ $t('tax') }}</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in data.billCategories"
									:key="index"
								>
									<td>{{ item.category }}</td>
									<td>{{ item.description }}</td>
									<td>{{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.amount) }}</td>
									<td>{{ item.taxDescription }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<v-card-text>
						<v-row class="mt-4">
							<v-col cols="9">
							</v-col>
							<v-col>
								<v-row class="">
									<v-col class="text-right font-weight-bold">{{ $t('subtotal') }}</v-col>
									<v-col class="text-right font-weight-bold">${{ new Intl.NumberFormat('en-US').format(subTotal) }}</v-col>
								</v-row>
								<v-row class="">
									<v-col class="text-right font-weight-medium">{{ $t('tax') }}</v-col>
									<v-col class="text-right font-weight-bold">${{ new Intl.NumberFormat('en-US').format(taxTotal) }}</v-col>
								</v-row>
								<v-row>
									<v-col class="text-right font-weight-bold">{{ $t('total') }}</v-col>
									<v-col class="text-right font-weight-bold">${{ new Intl.NumberFormat('en-US').format(subTotal + taxTotal) }}</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				<expense-form :open="showEditModal" :form-values="data" :is-edit-mode="true" @toggle="onToggleEditModal"></expense-form>
				<expense-delete :open="showDeleteModal" :form-data="data" @toggle="showDeleteModal = !showDeleteModal"></expense-delete>
      </v-container>
    </v-app>
  </div>
</template>

<script>

import ExpenseForm from "../components/ExpenseForm.vue";
import ExpenseDelete from '../components/ExpenseDeleteModal.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		ExpenseForm,
		ExpenseDelete,
	},
	data() {
		return {
			showEditModal: false,
			showPaymentModal: false,
			showDeleteModal: false,
			data: {}
		}
	},
  created() {
    //Fetch the data from api here.
		console.log('fetching data');
		this.data = {
			refNumber: this.$route.params.id || '',
			payee: 'JG Logistics',
			paymentMethod: 'Master Card',
			paymentAccount: 'Master Card - USD',
			paymentDate: 'Jan. 1, 2021',
			billCategories: [
				{
					id: 1,
					category: 'Freight & Shipping Cost',
					description: '--',
					amount: 3750,
					taxAmount: 375,
					taxDescription: '$ 375.00 (10% GST)'
				}
			],
		}
  },
  computed: {
    //
		breadCrumb() {
			return [
        {
          text: this.$t('side_menu_expenses'),
          disabled: false,
          href: '/accounting/bills',
        },
        {
          text: this.$t('bill'),
          disabled: true,
          href: '#',
        },
        {
          text: this.$route.params.id || '',
          disabled: true,
          href: '#',
        },
      ];
		},
		refNumber: {
			get() {
				return this.$route.params.id || '';
			}
		},

		subTotal(){
			if(this.data.billCategories.length) {
				return this.data.billCategories.reduce((c, n) => c + Number(n.amount) ,0);
			}
			return 0;
		},

		taxTotal() {
			if(this.data.billCategories.length) {
				return this.data.billCategories.reduce((c, n) => c + Number(n.taxAmount) ,0);
			}
			return 0;
		}

  },
  methods: {
    //

		onToggleEditModal() {
			this.showEditModal = !this.showEditModal;
		}
  }

};
</script>
<style lang="scss" scoped>
	$button-bg-color: #0171a1;
	$form-label: #819fb2;
	.full-width {
		margin: 0 0 0 223px;
		height: auto !important;
	}

	.is-mobile {
		.full-width {
			padding: 0 24px 0px 24px;
			margin: 0;
		}
	}
	.vue-app {
		background: none;
	}

	.form-label {
		color: $form-label;
		font-weight: bold;
	}

	hr {
		border-color: #ffffff !important;
	}
	.btn-primary {
		background-color: $button-bg-color !important;
		color: #fff !important;
	}
</style>
<style lang="scss">
	.container {
    padding: 0;
  }
	.v-table-middle-align {
		width: 100%;
    td, th {
      vertical-align: middle !important;
    }
  }
</style>