<template>
	<div class="text-center">
		<v-dialog
			v-model="showDialog"
			max-width="480"
			origin="top center"
			class="dialog pa-0"
			persistent
			scrollable
		>
			<v-card>

				<v-card-text class="pa-8">
          <v-icon size="58" color="orange">mdi-alert-circle-outline</v-icon>
          <h1 class="header-1 mt-4">{{ $t('delete_expense') }}</h1>
					{{ $t('delete_expense_message') }} "{{ formData.billNumber }}"?

          <div class="mt-8">
            <v-btn
              class="mr-2 white--text text-capitalize"
              color="#0171a1"
              depressed
              large
              @click="onClose"
            >
              {{ $t('delete') }}
            </v-btn>
            <v-btn
              outlined
              large
              depressed
              class="teal--text text-capitalize"
              @click="onClose"
            >
              {{ $t('cancel') }}
            </v-btn>
          </div>
				</v-card-text>

			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: ["open", "formData"],

	computed: {
		showDialog: {
			get() {
				return this.open;
			},
			set(value) {
				this.$emit("toggle", value);
				this.formData = {
					billCategories: [],
				};
			},
		},
	},

	methods: {


		onClose() {
			this.$emit("toggle");
		},

	},
};
</script>

<style lang="scss" scoped>
	.dialog {
		z-index: 9999;
	}
</style>